import React from 'react';
import { graphql } from 'gatsby';

import Post from '../sections/Post/Post';

export default function PrintPost({ data: { mdx, site }, pageContext }) {
    const { postPath, previous, next } = pageContext;
    const publicUrl = `${site.siteMetadata.siteUrl}${postPath}`;

    return (
        <>
            <Post
                post={mdx}
                publicUrl={publicUrl} />
        </>
    )
}

export const pageQuery = graphql`
query PrintPostQuery($id: String) {
    site {
        siteMetadata {
            siteUrl
        }
    }
    mdx(id: { eq: $id }) {
        fields {
            slug
            path
        }
        tableOfContents
        timeToRead
        frontmatter {
            title
            description
            categories
            date(formatString: "MMMM DD, YYYY")
            toc
            featuredImage {
                seoImage: childImageSharp {
                    fixed(width: 1200, height: 630) {
                        src
                        srcWebp
                    }
                }

                childImageSharp {
                    fluid(maxWidth: 1024) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
        body
    }
}
`
